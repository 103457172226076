<template>
  <v-container
    class="z-end-client"
  >
    <!-- Dialog - Add special offer product -->
    <v-dialog
      v-model="dialogAddSpecialOffer"
      max-width="500px"
    >
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-text-field
                  v-model="addSpecialOfferItem.qty"
                  :label="$t('Common.qty')"
                  type="Number"
                  :rules="[
                    (v) => !!v || $t('EndClient.rule_number_greater_than_zero'),
                    (v) =>
                      /^[1-9]\d*$/.test(v) ||
                      $t('EndClient.rule_number_greater_than_zero'),
                  ]"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            @click="closeAddSpecialOfferDialog"
          >
            {{ $t("Common.cancel") }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            :loading="isSavingSpecialOffer"
            @click="saveSpecialOffer"
          >
            {{ $t("Common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog - Add special offer product -->

    <!-- Dialog - Add product -->
    <v-dialog
      v-model="dialogAddProduct"
      max-width="800px"
    >
      <v-card>
        <v-card-title class="text-h5 z-card-title">
          <v-icon left>
            mdi-bookmark-outline
          </v-icon>
          {{ $t("EndClient.add_product") }}
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs8
                sm8
                md8
              >
                <v-select
                  v-model="allProdsSelectedItem.product"
                  :label="$t('EndClient.product')"
                  :items="productList"
                  item-text="product_name"
                  item-value="id"
                  return-object
                  persistent-hint
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs8
                sm8
                md8
              >
                <v-text-field
                  v-model="allProdsSelectedItem.product.price"
                  :label="$t('Common.price')"
                  disabled
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs8
                sm8
                md8
              >
                <v-text-field
                  v-model="allProdsSelectedItem.qty"
                  :label="$t('Common.qty')"
                  type="Number"
                  :rules="[
                    (v) => !!v || $t('EndClient.rule_number_greater_than_zero'),
                    (v) =>
                      /^[1-9]\d*$/.test(v) ||
                      $t('EndClient.rule_number_greater_than_zero'),
                  ]"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            @click="closeAddProductDialog"
          >
            {{ $t("Common.cancel") }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            :loading="isSavingAddProduct"
            @click="saveAddProduct"
          >
            {{ $t("Common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog - Add product -->

    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="imageAvatar"
          lazy-src="require('../../assets/img/Zwono_Logo-H-Colors-RGB-228x70.svg')"
          class="my-3"
          contain
          height="200"
          dark
        />
        
        <div
          v-if="isSpinnerActive"
          class="table-spinner-container"
        >
          <v-progress-circular
            color="primary"
            indeterminate
            :size="100"
            :width="8"
          />
        </div>
        <template v-else>
          <v-col 
            v-if="isInitFailed"
            class="mb-4"
          >
            <h1 class="display-2 font-weight-bold mb-3">
              {{ $t("EndClient.no-data-for-order") }}
            </h1>
          </v-col>
          <template
            v-else 
          >
            <v-col class="mb-4">
              <h1 class="display-2 font-weight-bold mb-3">
                {{ $t("EndClient.delivery_time_confirmation_title") }}
              </h1>

              <h3 
                v-if="endClientData && endClientData.customer"
                class="subheading font-weight-regular"
              >
                {{ $t("EndClient.customer_intro") }}
                {{ endClientData.customer.customer_name }} <br>{{
                  $t("EndClient.customer_text")
                }}
                <a
                  :href="endClientData.company.endclient_url"
                  target="_blank"
                >{{
                  endClientData.company.company_name
                }}</a>
              </h3>
            </v-col>

            <v-col
              class="mb-5"
              cols="12"
            >
              <v-card
                elevation="15"
                outlined
                tile
              >
                <v-card-title>{{ $t("EndClient.order_data") }}</v-card-title>
                <v-card-text v-if="endClientData">
                  <p>
                    <b class="mr-1">{{ $t("EndClient.order_date") }}</b>
                    {{ formatDate(endClientData.order_date) }}
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.order_products") }}</b>
                  </p>
                  <p class="z-order-table-container">
                    <v-data-table
                      :loading-text="$t('Common.loading-text')"
                      :headers="headerOrderItems"
                      :items="endClientData.items"
                      hide-default-footer
                      dense
                    >
                      <!-- <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Proizvod</th>
                      <th class="text-left">Kolicina</th>
                      <th class="text-left">Jed. cena</th>
                      <th class="text-left">Ukupno</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in order.items" :key="item.id">
                      <td>{{ item.product_name }}</td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.price }}</td>
                      <td>{{ item.qty * item.price }}</td>
                    </tr>
                  </tbody>
                </template> -->
                      <template #[`item.total_price`]="{ item }">
                        {{ item.qty * item.price }}
                      </template>
                      <template #[`item.actions`]="{ item }">
                        <v-icon
                          v-if="endClientData && !endClientData.delivery?.is_dispatched && !isConfirmedOrDeclined
                          "
                          small
                          @click="showDeleteModal(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </p>
                  <p v-if="endClientData && !endClientData.delivery?.is_dispatched && !isConfirmedOrDeclined">
                    <v-btn
                      icon
                      color="green"
                      @click="openAddProductDialog()"
                    >
                      <v-icon>mdi-plus</v-icon>{{ $t("Common.new_item") }}
                    </v-btn>
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.order_total_price") }}</b>
                    {{ endClientData.price }} {{ $t("EndClient.order_currency") }}
                  </p>
                  <p>
                    <i>{{ $t("EndClient.order_delivery_cost") }}
                      {{ endClientData.delivery_cost }}
                      {{ $t("EndClient.order_currency") }}</i>
                  </p>
                </v-card-text>
              </v-card>
              <br>
              <v-card
                elevation="15"
                outlined
                tile
              >
                <v-card-title>{{ $t("EndClient.customer_data") }}</v-card-title>
                <v-card-text
                  v-if="endClientData && endClientData.customer"
                >
                  <p>
                    <b class="mr-1">{{ $t("EndClient.customer_name") }}</b>
                    <span class="d-inline-block">{{
                      endClientData.customer.customer_name
                    }}</span>
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.customer_phone") }}</b>
                    <span class="d-inline-block">{{
                      endClientData.customer.phone_number
                    }}</span>
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.customer_email") }}</b>
                    <span class="d-inline-block text-break">{{
                      endClientData.customer.email
                    }}</span>
                  </p>
                </v-card-text>
              </v-card>
              <br>
              <v-card
                elevation="15"
                outlined
                tile
              >
                <v-card-title>
                  {{ $t("EndClient.customer_address_data") }}
                </v-card-title>
                <v-card-subtitle 
                  v-if="endClientData && endClientData.delivery?.is_dispatched"
                  class="mt-3"
                >
                  <v-alert
                    type="info"
                  >
                    {{ $t("EndClient.already_dispatched") }}
                  </v-alert>
                </v-card-subtitle>
                <v-card-subtitle 
                  v-else
                  class="mt-3"
                >
                  <v-alert
                    v-if="endClientData.endclient_status === 'Confirmed'"
                    type="success"
                  >
                    {{ $t("EndClient.action_confirm_msg") }}
                  </v-alert>
                  <v-alert
                    v-if="endClientData.endclient_status === 'Declined'"
                    type="error"
                  >
                    {{ $t("EndClient.action_decline_msg") }}
                  </v-alert>
                </v-card-subtitle>
                <v-card-text
                  v-if="endClientData && endClientData.customer"
                >
                  <p>
                    <b class="mr-1">{{ $t("EndClient.customer_address_country") }}</b>
                    <span class="d-inline-block">{{
                      endClientData.customer.country
                    }}</span>
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.customer_address_city") }}</b>
                    <span class="d-inline-block">{{
                      endClientData.customer.city
                    }}</span>
                  </p>
                  <p>
                    <b class="mr-1 d-inline-block">{{
                      $t("EndClient.customer_address_street")
                    }}</b>
                    <span class="d-inline-block">{{
                      endClientData.customer.address1 +
                        " " +
                        [
                          endClientData.customer.house_number,
                          endClientData.customer.floor_details,
                          endClientData.customer.appartment_details,
                        ]
                          .filter(Boolean)
                          .join(" / ")
                    }}</span>
                  </p>
                </v-card-text>
                <v-card-title
                  v-if="endClientData && endClientData.alternative_address"
                >
                  {{ $t("EndClient.customer_changed_address_data") }}
                </v-card-title>
                <v-card-text
                  v-if="endClientData && endClientData.alternative_address"
                >
                  <p>
                    <b class="mr-1">{{ $t("EndClient.customer_address_country") }}</b>
                    <span class="d-inline-block">{{
                      endClientData.alternative_address.country
                    }}</span>
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.customer_address_city") }}</b>
                    <span class="d-inline-block">{{
                      endClientData.alternative_address.city
                    }}</span>
                  </p>
                  <p>
                    <b class="mr-1 d-inline-block">{{ $t("EndClient.customer_address_street") }} </b>
                    <span class="d-inline-block">{{ endClientData.alternative_address.address1 }}</span>
                  </p>
                </v-card-text>
                <v-card-actions 
                  v-if="endClientData && !endClientData.delivery?.is_dispatched"
                  class="justify-center"
                >
                  <v-btn
                    v-if="!isConfirmedOrDeclined"
                    color="zwonogreen"
                    @click="changeAlternateAddressVisibility(true)"
                  >
                    {{ $t("EndClient.change_address_btn") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              <br>
              <v-card
                elevation="15"
                outlined
                tile
                class="z-delivery-time"
              >
                <v-card-title>{{ $t("EndClient.timeslot_data") }}</v-card-title>
                <v-card-text>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.timeslot_date") }}&nbsp;</b>
                    <span
                      v-if="endClientData && endClientData.delivery"
                      class="d-inline-block"
                    >{{
                      formatDate(endClientData.delivery.delivery_date)
                    }}</span>
                    <span v-else>{{ $t("Common.does_not_exist_yet") }}</span>
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.timeslot_time") }}</b>
                    <span v-if="endClientData && endClientData.timeslot">
                      {{
                        endClientData.timeslot.start +
                          " - " +
                          endClientData.timeslot.end
                      }}
                    </span>
                    <span v-else>
                      {{ $t("Common.does_not_exist_yet") }}
                    </span>
                  </p>
                  <p>
                    <b class="mr-1">{{ $t("EndClient.timeslot_customer_note") }}</b>
                    <span
                      v-if="endClientData && endClientData.note" 
                      class="d-inline-block"
                    >{{ endClientData.note }}</span>
                  </p>
                </v-card-text>
              </v-card>
              <h2 class="headline font-weight-bold pt-5 mt-5 mb-0">
                {{ $t("EndClient.specreqs_intro") }}
              </h2>
              <h3>
                {{ $t("EndClient.specreqs_text1") }}
                <br>
                {{ $t("EndClient.specreqs_text2") }}
              </h3>
              <v-card
                elevation="15"
                outlined
                tile
              >
                <v-card-title>{{ $t("EndClient.specreqs_title") }}</v-card-title>
                <v-card-subtitle
                  v-if="endClientData && !endClientData.delivery?.is_dispatched"
                  class="mt-3"
                >
                  <v-alert
                    v-if="endClientData.endclient_status === 'Confirmed'"
                    type="success"
                  >
                    {{ $t("EndClient.action_confirm_msg") }}
                  </v-alert>
                  <v-alert
                    v-if="endClientData.endclient_status === 'Declined'"
                    type="error"
                  >
                    {{ $t("EndClient.action_decline_msg") }}
                  </v-alert>
                  <span v-else>
                    {{ $t("EndClient.specreqs_sub") }}
                  </span>
                </v-card-subtitle>
                <v-card-subtitle 
                  v-else
                  class="mt-3"
                >
                  <v-alert
                    type="info"
                  >
                    {{ $t("EndClient.already_dispatched") }}
                  </v-alert>
                </v-card-subtitle>
                <v-card-text>
                  <v-textarea
                    v-if="endClientData && 'endclient_special_request' in endClientData"
                    v-model="endClientData.endclient_special_request"
                    :label="$t('EndClient.specreqs_request')"
                    :hint="$t('EndClient.specreqs_example')"
                    :disabled="isConfirmedOrDeclined || (endClientData && endClientData.delivery?.is_dispatched)"
                  />
                </v-card-text>
              </v-card>
              <br>
              <v-card
                v-if="endClientData && !endClientData.delivery?.is_dispatched"
                elevation="15"
                outlined
                tile
                :disabled="isConfirmedOrDeclined"
              >
                <v-card-title>{{ $t("EndClient.special_offer_title") }}</v-card-title>
                <v-card-subtitle>
                  {{ $t("EndClient.special_offer_sub") }}
                </v-card-subtitle>
                <v-card-text>
                  <v-container fluid>
                    <v-row dense>
                      <v-col
                        v-for="offer in specialOfferProducts"
                        :key="offer.id"
                        :cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-card class="special-offer-card">
                          <v-img
                            :src="offer.imageBase64"
                            lazy-src="require('../../assets/default_product.jpg')"
                            aspect-ratio="1"
                          >
                            <!-- class="white--text align-end" -->
                            <v-card-title>
                              {{ offer.product_name }}
                            </v-card-title>
                            <div class="special-offer-flex-container">
                              <v-card-subtitle class="special-offer-subtitle">
                                <b class="mr-1">{{ $t("EndClient.special_offer_action_price") }} {{ offer.action_price }}
                                  {{ $t("EndClient.order_currency") }}</b>
                              </v-card-subtitle>
                              <v-card-text>
                                {{ $t("EndClient.special_offer_price") }}
                                {{ offer.price }} {{ $t("EndClient.order_currency") }}
                              </v-card-text>
                            </div>
                          </v-img>

                          <!-- "require('../../assets/default_product.jpg')" -->
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="zwonogreen"
                              @click="openAddSpecialOfferDialog(offer)"
                            >
                              {{ $t("EndClient.add-to-cart") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <br>
              <!-- Order status -->
              <v-card
                elevation="15"
                outlined
                tile
              >
                <div v-if="isConfirmedOrDeclined && (endClientData && !endClientData.delivery?.is_dispatched)">
                  <v-card-title>{{ $t("EndClient.action_status") }}</v-card-title>
                  <v-card-subtitle class="mt-3">
                    <v-alert
                      v-if="endClientData.endclient_status === 'Confirmed'"
                      type="success"
                    >
                      {{ $t("EndClient.action_confirm_msg") }}
                    </v-alert>
                    <v-alert
                      v-if="endClientData.endclient_status === 'Declined'"
                      type="error"
                    >
                      {{ $t("EndClient.action_decline_msg") }}
                    </v-alert>
                  </v-card-subtitle>
                  <v-card-text>
                    <p v-if="endClientData.endclient_status === 'Confirmed'">
                      {{ $t("EndClient.action_url") }}
                      <a :href="statusURL">{{ statusURL }}</a>
                    </p>
                  </v-card-text>
                </div>
                <template v-else>
                  <v-card-title>
                    {{ $t("EndClient.action_title") }}
                  </v-card-title>
                  <v-card-subtitle v-if="endClientData && !endClientData.delivery?.is_dispatched">
                    {{
                      isConfirmedOrDeclined
                        ? $t("EndClient.action_change")
                        : $t("EndClient.action_sub")
                    }}<br>
                  </v-card-subtitle>
                  <v-card-text v-if="endClientData && !endClientData.delivery?.is_dispatched">
                    <div class="text-center">
                      <v-btn
                        class="ma-2"
                        outlined
                        color="green"
                        @click="changeConfirmationModalVisibility(true)"
                      >
                        {{ $t("EndClient.action_confirm") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        outlined
                        color="red"
                        @click="changeDeclineModalVisibility(true)"
                      >
                        {{ $t("EndClient.action_decline") }}
                      </v-btn>
                    </div>
                  </v-card-text>
                  <v-card-subtitle
                    v-if="endClientData && endClientData.delivery?.is_dispatched"
                    class="mt-3"
                  >
                    <v-alert type="info">
                      {{ $t("EndClient.already_dispatched") }}
                    </v-alert>
                    <v-card-text>
                      <p>
                        {{ $t("EndClient.action_url") }}
                        <a :href="statusURL">{{ statusURL }}</a>
                      </p>
                    </v-card-text>
                  </v-card-subtitle>
                </template>
              </v-card>

              <br>
            </v-col>
          </template>
        </template>
      </v-col>
    </v-row>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      alert-text=""
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
    <change-deal-alternative-address-modal 
      v-if="endClientData && endClientData.customer"
      :is-visible="isAlternativeAddressModalVisible"
      :order-id="orderId"
      :customer-name="endClientData.customer.customer_name"
      :is-user-change="true"
      @is-visible="(value) => changeAlternateAddressVisibility(value)"
      @is-changed="(item) => alternativeAddressChanged(item)"
    />
    <confirmation-modal
      :is-visible="isConfirmationModalVisible"
      :title="$t('EndClient.action_confirm')"
      :text="$t('EndClient.confirm_delivery_modal_text')"
      :confirm-text="$t('EndClient.action_confirm')"
      :cancel-text="$t('EndClient.action_decline')"
      @is-visible="value => changeConfirmationModalVisibility(value)"
      @confirm-click="confirmOrder"
    />
    <confirmation-modal
      :is-visible="isDeclineModalVisible"
      :title="$t('EndClient.action_decline')"
      text=""
      :confirm-text="$t('Common.save')"
      :cancel-text="$t('Common.cancel')"
      @is-visible="value => changeDeclineModalVisibility(value)"
      @confirm-click="declineOrder"
    >
      <template #content>
        <v-textarea
          v-if="endClientData"
          v-model="endClientData.endclient_decline_reason"
          class="ma-2"
          :label="$t('EndClient.delivery_confirmation_reason')"
          :hint="$t('EndClient.delivery_confirmation_reason_hint')"
        />
      </template>
    </confirmation-modal>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import axiosInstance from '@/plugins/axiosInstance'
import ChangeDealAlternativeAddressModal from "../../components/modals/ChangeDealAlternativeAddressModal.vue"
import ConfirmationModal from "../../components/modals/ConfirmationModal.vue"

export default {
  name: "EndClient",
  components: {
    ChangeDealAlternativeAddressModal,
    ConfirmationModal
  },
  data: () => ({
    isSpinnerActive: true,
    isInitFailed: false,
    orderId: "",
    isDeleteModalVisible: false,
    deleteModalItemId: 0,
    dialogAddSpecialOffer: false,
    addSpecialOfferItem: {
      qty: 1,
    },
    dialogAddProduct: false,
    allProdsSelectedItem: {
      product: {},
      qty: 1,
      product_name: "",
      price: 0,
    },
    imageAvatar: "",
    isSavingSpecialOffer: false,
    isSavingAddProduct: false,
    isAlternativeAddressModalVisible: false,
    isConfirmationModalVisible: false,
    isDeclineModalVisible: false,
  }),
  computed: {
    ...mapGetters("endclient", ["endClientData", "specialOfferProducts"]),
    ...mapGetters("product", ["productList"]),

    isConfirmedOrDeclined() {
      if (
        this.endClientData?.endclient_status === "Confirmed" ||
        this.endClientData?.endclient_status === "Declined"
      ) {
        return true;
      }
      return false;
    },
    statusURL() {
      var loc = window.location.href;
      loc = loc.replace("/endclient", "/endclient/status");
      return loc;
    },
    headerOrderItems() {
      return [
        {
          text: this.$t("EndClient.table_header_product"),
          value: "product_name",
          filterable: false,
        },
        {
          text: this.$t("EndClient.table_header_qty"),
          value: "qty",
          filterable: false,
        },
        {
          text: this.$t("EndClient.table_header_price"),
          value: "price",
          filterable: false,
        },
        { text: this.$t("EndClient.table_header_total"), value: "total_price" },
        {
          text: this.$t("EndClient.table_header_actions"),
          value: "actions",
          filterable: false,
        },
      ];
    },
  },
  // lifecycle hooks
  async mounted() {
    const id = this.$route.query?.id;
    if (id) {
      this.orderId = id;
      await this.initialize();
    }
  },
  beforeDestroy() {
    this.clearEndClientState();
  },

  methods: {
    ...mapActions("endclient", [
      "getEndClientData",
      "getSpecialOfferProductsForOrder",
      "updateStatus",
      "clearEndClientState",
    ]),
    ...mapActions("product", ["getProductList"]),
    ...mapMutations("endclient", ["addEndClientItem", "removeEndClientItem"]), //these should be called via action?

    async initialize() {
      try {
        this.isSpinnerActive = true;
        this.isInitFailed = false;
        const responses = await Promise.all([
          this.getEndClientData(this.orderId),
          this.getSpecialOfferProductsForOrder(this.orderId),
          this.getAvatar(),
        ]);
        
        this.isSpinnerActive = false;
        if (responses[0] === false) this.isInitFailed = true;

        await this.getProductList({ itemsPerPage: 'all', role:sessionStorage.getItem("role")});

        const lang = this.endClientData.company.endclient_lang || 'sr-Latn-RS';
        sessionStorage.setItem("lang", lang);
        this.$i18n.locale = lang;
      } catch (error) {
      this.isInitFailed = true;
      // Handle the error as needed (e.g., log it or display an error message)
      } finally {
        this.isSpinnerActive = false;
      }
    },

    formatDate(value) {
      return new Date(value).toLocaleDateString();
    },

    async declineOrder() {
      const ord = {
        id: this.endClientData.id,
        items: this.endClientData.items,
        end_client_status: "Declined",
        end_client_special_request: "",
        end_client_decline_reason:
          this.endClientData?.endclient_decline_reason || "",
      };
      await this.updateStatus(ord);
      this.changeDeclineModalVisibility(false)
    },

    async changeConfirmationModalVisibility(value) {
      this.isConfirmationModalVisible = value
    },

    async changeDeclineModalVisibility(value) {
      this.isDeclineModalVisible = value
    },

    async confirmOrder() {
      this.changeConfirmationModalVisibility(false)
      const ord = {
        id: this.endClientData.id,
        items: this.endClientData.items,
        end_client_status: "Confirmed",
        end_client_special_request:
          this.endClientData?.endclient_special_request || "",
        end_client_decline_reason: "",
      };
      await this.updateStatus(ord);
    },

    openAddSpecialOfferDialog(offer) {
      this.addSpecialOfferItem = {
        qty: 1,
        product_name: offer.product_name,
        price: offer.action_price,
        product: { id: offer.id, product_name: offer.product_name, action_price: offer.action_price },
      };
      this.dialogAddSpecialOffer = true;
    },

    closeAddSpecialOfferDialog() {
      this.addSpecialOfferItem = { qty: 1 };
      this.dialogAddSpecialOffer = false;
    },

    saveSpecialOffer() {
      this.isSavingSpecialOffer = true;
      if (
        parseInt(this.addSpecialOfferItem.qty) &&
        this.addSpecialOfferItem.qty
      ) {
        this.addSpecialOfferItem.qty = parseInt(this.addSpecialOfferItem.qty)
        const maxNr = this.endClientData.items.reduce((p, c) =>
          p.nr > c.nr ? p : c
        ).nr;
        this.addSpecialOfferItem.nr = maxNr + 1;
        this.addEndClientItem(this.addSpecialOfferItem);
        this.addSpecialOfferItem = { qty: 1 };
        this.recalculateTotalOrderPrice();
        this.dialogAddSpecialOffer = false;
      }
      this.isSavingSpecialOffer = false;
    },

    openAddProductDialog() {
      this.dialogAddProduct = true;
    },

    closeAddProductDialog() {
      this.allProdsSelectedItem = {
        product: {},
        qty: 1,
        product_name: "",
        price: 0,
      };
      this.dialogAddProduct = false;
    },

    saveAddProduct() {
      this.isSavingAddProduct = true;
      if (
        parseInt(this.allProdsSelectedItem.qty) &&
        this.allProdsSelectedItem.qty
      ) {
        this.allProdsSelectedItem.qty = parseInt(this.allProdsSelectedItem.qty)
        this.allProdsSelectedItem.product_name =
          this.allProdsSelectedItem.product.product_name;
        this.allProdsSelectedItem.price =
          this.allProdsSelectedItem.product.price;

        this.addEndClientItem(this.allProdsSelectedItem);
        this.allProdsSelectedItem = {
          product: {},
          qty: 1,
          product_name: "",
          price: 0,
        };
        this.recalculateTotalOrderPrice();
        this.dialogAddProduct = false;
      }
      this.isSavingAddProduct = false;
    },

    async recalculateTotalOrderPrice() {
      this.endClientData.price = this.endClientData.items.reduce(
        (accum, item) => accum + item.qty * item.price,
        0
      );
    },

    showDeleteModal(item) {
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    deleteItem() {
      this.removeEndClientItem(this.deleteModalItemId);
      this.recalculateTotalOrderPrice();
      this.isDeleteModalVisible = false;
    },

    async getAvatar() {
      axiosInstance.get(`/orders/endclient/${this.orderId}/logo`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var content_type = response.headers["content-type"];
          const buffer = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          this.imageAvatar =
            `data:${content_type};charset=utf-8;base64,` + buffer;
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log("Get avatar error: " + error);
          }
        });
    },

    changeAlternateAddressVisibility(isVisible) {
      this.isAlternativeAddressModalVisible = isVisible
    },

    alternativeAddressChanged(item) {
      this.endClientData.alternative_address = item.addressObj
    },
  },
};
</script>
